import React from 'react'
import { useParams } from 'react-router-dom';
import Courses from './EnrolledCourses';

function Search() {

  return (
    <div>
        <Courses />
    </div>
  )
}

export default Search