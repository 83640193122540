import { ApiSlice } from './ApiSlice';
import { BASE_URL } from '../Constants';

export const UserApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: `${ BASE_URL }/auth/login/`,
                method: 'POST',
                body: data,
            }),
        }),
        signup: builder.mutation({
            query: (data) => ({
                url: `${ BASE_URL }/auth/register/`,
                method: 'POST',
                body: data,
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: `${ BASE_URL }/auth/logout/`,
                method: 'POST',
             
                
            }),
        }),
        profile: builder.query({
            query: () => ({
                url: `${ BASE_URL }/auth/profile/`,
                method: 'GET',
                
            }),
        }),

        progress: builder.query({
            query: () => ({
                url: `${ BASE_URL }/auth/progress/`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const { 
    useLoginMutation, 
    useSignupMutation, 
    useLogoutMutation, 
    useProfileQuery,
    useProgressQuery,
} = UserApiSlice;
